<template>
  <div class="overflow-x-hidden md:w-5/6 xl:w-4/5 w-full mx-auto grid justify-items-center">
    <LayoutsPageNavbar class="h-[64px] max-h-[64px]" />
    <LayoutsPageContent class="mx-8">
      <slot />
    </LayoutsPageContent>
    <LayoutsPageFooter class="h-[42px] w-full" />
  </div>
</template>
<script setup lang="ts">

</script>
